import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ButtonBar, Main, Module } from 'layouts';
import NotFound from 'pages/not-found';
import { PropTypes } from 'prop-types';

import {
  AnimationList,
  BackButton,
  Button,
  IfUserIs,
  Reactions,
  ReactionSelector,
  ThemedHeading,
  ThemedPage,
  Thread,
  UserPill,
} from 'components';
import ReactionsShower from 'components/reactions/reactions-shower';

import { PixelMode } from '../../../gql/generated/graphql';
import { ANIMATION_PAGE } from '../../../gql/queries/animation-page';
import { MUTATE_ANIMATION } from '../../../gql/queries/mutate-animation';
import { MUTATE_ANIMATION_VIEW } from '../../../gql/queries/mutate-animation-view';
import config from '../../../lib/config';
import currentUserSelector from '../../lib/selectors/current-user-selector';

import { AnimationJsonLd } from './animation-jsonld';
import { AnimationMeta } from './animation-meta';
import { PublicStatus } from './public-status';
import { ReactionsBreakdown } from './reactions-breakdown';
import { ShareButton } from './share-button';
import { Video } from './video';

dayjs.extend(relativeTime);

import { ButtonGroup } from 'layouts/button-group';

import { showModal } from '../../lib/modal/modal-actions';

const AnimationPage = ({ match, staticContext }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);

  const {
    loading,
    data = {
      animation: {
        sequence: [],
        user: { theme: {} },
        thread: { posts: [] },
        reactionsBreakdown: [],
        olderAnimations: [],
      },
    },
  } = useQuery(ANIMATION_PAGE, {
    variables: { slug: match.params.animationUrl },
    fetchPolicy: 'cache-and-network',
  });

  const [mutateAnimation] = useMutation(MUTATE_ANIMATION);
  const [mutateAnimationView] = useMutation(MUTATE_ANIMATION_VIEW);

  const onReportClicked = () => {
    dispatch(showModal('ReportModal', { id: data.animation.id }));
  };

  const deleteAnimation = () => {
    dispatch(
      showModal('ConfirmAnimationDeleteModal', { id: data.animation.id })
    );
  };

  const blacklistAnimation = () => {
    mutateAnimation({
      variables: { id: data.animation.id, public: false, blacklisted: true },
    });
  };

  useEffect(() => {
    if (data.animation.id) {
      mutateAnimationView({
        variables: { id: data.animation.id },
      });
    }
  }, [data.animation.id]);

  useEffect(() => {
    try {
      (window.adsbygoogle || []).push({});
    } catch (e) {
      console.error(e);
    }
  }, []);

  if (data.animation.id === -1) {
    if (staticContext) staticContext.status = 404;
    return <NotFound />;
  }

  const getUserActions = () => {
    return (
      <Fragment>
        <Button
          icon="fas fa-edit"
          type="positive"
          to={`/editor/${data.animation.url}`}
        >
          Edit
        </Button>
        <Button
          icon="fas fa-trash-alt"
          type="negative"
          onClick={deleteAnimation}
          rightAlign
        >
          Delete
        </Button>
      </Fragment>
    );
  };

  const getAdminActions = () => {
    if (data.animation.blacklisted) return null;
    return (
      <Fragment>
        <Button icon="fas fa-ban" type="negative" onClick={blacklistAnimation}>
          Blacklist
        </Button>
      </Fragment>
    );
  };

  const getReportButton = () => {
    return (
      <Button icon="fas fa-flag" onClick={onReportClicked} type="negative">
        Report inappropriate
      </Button>
    );
  };

  const getLength = () => {
    if (data.animation.sequence.length === 0) return {};
    if (data.animation.schemaVersion > 1) {
      return {
        frameCount: data.animation.frames,
        duration: data.animation.sequence
          .split('/')
          .reduce((total, frameParts) => {
            const [delay] = frameParts.split(':');
            return total + parseInt(delay, 10);
          }, 0),
      };
    }

    try {
      const sequence = JSON.parse(data.animation.sequence);
      return {
        frameCount: sequence.length,
        duration: sequence.reduce((total, frame) => {
          return total + parseInt(frame[1], 10);
        }, 0),
      };
    } catch (e) {
      return {
        frameCount: data.animation.sequence.length,
        duration: 0,
      };
    }
  };

  const { animation } = data;
  const { frameCount, duration } = getLength();
  const belongsToCurrentUser = currentUser.id === animation.userId;

  const getPosterPath = () => {
    if (!animation.url) return null;

    if (animation.pixelMode === PixelMode.Standard) {
      return animation.schemaVersion > 1
        ? `${config.s3Path}/${animation.url}/poster.png?v=${animation.version}`
        : `${config.s3Path}/${animation.url}/0.png?v=${animation.version}`;
    } else {
      return animation.schemaVersion > 1
        ? `${config.s3Path}/${animation.url}/animation.gif?v=${animation.version}`
        : `${config.s3Path}/${animation.url}/0.png?v=${animation.version}`;
    }
  };

  const posterPath = getPosterPath();

  return (
    <ThemedPage theme={data.animation.user.theme}>
      <Main theme={data.animation.user.theme}>
        <Helmet>
          <title>
            The Animator - {`${data.animation.title} by ${animation.user.name}`}
          </title>
        </Helmet>
        <AnimationMeta animation={animation} />
        <AnimationJsonLd animation={animation} />
        <ThemedHeading theme={data.animation.user.theme}>
          {data.animation.title} by <UserPill user={data.animation.user} />
        </ThemedHeading>

        <ButtonBar>
          <ButtonGroup>
            <BackButton />
          </ButtonGroup>
          <ButtonGroup align="right">
            {currentUser.roles.includes('admin') ? getAdminActions() : null}
            <ShareButton animation={data.animation} />
            <IfUserIs username={data.animation.userId}>
              {getUserActions()}
            </IfUserIs>
            <IfUserIs not username={data.animation.userId}>
              {getReportButton()}
            </IfUserIs>
          </ButtonGroup>
        </ButtonBar>
        <Module>
          <IfUserIs username={data.animation.userId}>
            <PublicStatus isPublic={data.animation.public} />
          </IfUserIs>
        </Module>
        <div className="animation-page__video-area">
          <div className="animation-page__video-container">
            {data.animation.pixelMode === PixelMode.Standard &&
            frameCount > 1 ? (
              <Video animation={data.animation} />
            ) : (
              posterPath && (
                <img
                  src={posterPath}
                  style={{
                    width: '100%',
                    imageRendering:
                      animation.pixelMode === PixelMode.Pixelated
                        ? 'pixelated'
                        : 'auto',
                  }}
                />
              )
            )}
          </div>
        </div>
        <Module>
          <ul className="animation-page__stats">
            <li>Views: {animation.views}</li>
            <li>Created: {dayjs(animation.dateCreated).fromNow()}</li>
            <li>Last updated: {dayjs(animation.dateUpdated).fromNow()}</li>
            <li>Version: {animation.version}</li>
            <li>
              Length:{' '}
              {frameCount > 1 ? `${frameCount} frames` : `${frameCount} frame`},{' '}
              {duration / 1000}s
            </li>
          </ul>
        </Module>
        {belongsToCurrentUser ? null : (
          <Module>
            <ReactionSelector
              animationId={data.animation.id}
              reactionsBreakdown={data.animation.reactionsBreakdown}
            />
          </Module>
        )}
        <Module>
          <div className="animation-page__reactions">
            <Reactions reactions={data.animation.reactions} type="large" />
          </div>
        </Module>
        <Module>
          <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-5569600601154152"
            data-ad-slot="4798805423"
            data-ad-format="auto"
            data-full-width-responsive="true"
          />
        </Module>
        <Module>
          <ReactionsBreakdown reactions={data.animation.reactionsBreakdown} />
        </Module>
        <Thread
          thread={data.animation.thread}
          type="ANIMATION"
          animation={data.animation}
        />
        <Module>
          {data.animation.olderAnimations.length > 0 ? (
            <p>More animations by {animation.user.name}:</p>
          ) : null}
          <AnimationList
            animations={data.animation.olderAnimations}
            showPublic={false}
            loading={loading}
          />
        </Module>
      </Main>
      <ReactionsShower reactions={data.animation.reactionsBreakdown} />
    </ThemedPage>
  );
};

AnimationPage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  staticContext: PropTypes.object,
};

export default AnimationPage;
