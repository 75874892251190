import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { HOMEPAGE_GALLERY } from '../../../gql/queries/homepage-gallery';
import AnimationList from '../../components/animation-list';
import Button from '../../components/button';
import ThemedPage from '../../components/themed-page';
import { ButtonBar, Main, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';

export default function Home() {
  const { loading, data = {} } = useQuery(HOMEPAGE_GALLERY, {
    variables: {
      limit: 8,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    try {
      (window.adsbygoogle || []).push({});
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <ThemedPage>
      <Main>
        <Helmet>
          <meta
            name="description"
            content="Create fun hand-drawn animations for free and share with the world!"
          />
        </Helmet>
        <Module>
          <div className="home-logo-container">
            <img
              src="/images/logo.png"
              className="home-logo"
              alt="The Animator logo"
            />
            <img
              src="/images/characters.png"
              alt=""
              className="home-characters"
            />
          </div>

          <div
            style={{
              background: '#f1c40f',
              borderRadius: '10px',
              padding: '10px',
              position: 'relative',
              boxShadow: '0 0 10px rgba(0,0,0,0.5)',
              marginBottom: '50px',
            }}
          >
            <p className="text-xl text-center font-black">
              Make your own hand-drawn animations and share with the world!
            </p>
            <br />
            <div style={{ textAlign: 'center' }}>
              <Button to="/editor">Get started!</Button>
            </div>
          </div>
        </Module>
        <Module wide light>
          <div className="split-layout">
            <div className="split-layout--small">
              <video
                autoPlay
                loop
                muted
                playsInline
                poster="/images/demo-video-poster.jpg"
                style={{ width: '100%' }}
              >
                <source src="/videos/demo-video.mp4" type="video/mp4" />
              </video>
            </div>

            <div className="split-layout--large">
              <h2>Web-based Editor</h2>
              <p>
                Use the powerful editor to draw your frames with the pencil,
                paintbrush and fill tools.
              </p>
              <p>
                Easily create an animation sequence complete with onion
                skinning.
              </p>
              <p>Works great on touch screens!</p>
            </div>
          </div>
        </Module>
        <Module>
          <div className="split-layout">
            <div className="split-layout--large">
              <h2>Easy Sharing</h2>
              <p>Upload your creation and share it with the world!</p>
              <p>
                Add reactions to show your appreciation for other people&apos;s
                creations.
              </p>
              <p>Sharing an animation is as easy as sending a link!</p>
            </div>
            <div className="split-layout--small">
              <img src="/images/pirate.jpg" alt="" style={{ width: '50%' }} />
            </div>
          </div>
        </Module>
        <Module wide light>
          <div className="split-layout">
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <span className="home-animations-total">
                {data.publicAnimationsCount}
              </span>
              <div className="home-animations-total-text">
                animations created to-date!
              </div>
            </div>
          </div>
        </Module>
        <Module>
          <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-5569600601154152"
            data-ad-slot="4798805423"
            data-ad-format="auto"
            data-full-width-responsive="true"
          />
        </Module>
        <Module>
          <h2>Recent animations</h2>
        </Module>
        <ButtonBar>
          <ButtonGroup align="right">
            <Button icon="fas fa-images" to="/gallery">
              See more animations
            </Button>
          </ButtonGroup>
        </ButtonBar>
        <Module>
          <AnimationList
            loading={loading}
            animations={data.dailyAnimations}
            showPublic={false}
          />
        </Module>
        <ButtonBar>
          <ButtonGroup align="right">
            <Button icon="fas fa-images" to="/gallery">
              See more animations
            </Button>
          </ButtonGroup>
        </ButtonBar>
      </Main>
    </ThemedPage>
  );
}

Home.propTypes = {
  animations: PropTypes.array,
};

Home.defaultProps = {
  animations: [],
};
